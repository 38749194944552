import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { GhostPost } from 'types/ghost'

import { typography, colors } from 'styles'
import PostAuthors from "./Post/PostAuthors"

const Title = styled.h3(
  {
    ...typography.headline3,
    margin: '0.1rem 0 0.5rem',
  },
  ({ featured }: { featured?: boolean }) =>
    featured && {
      ...typography.headline2,
    }
)

const CategoryLink = styled(Link)({
  ...typography.caption,
  textTransform: 'uppercase',
  color: colors.link,
  textDecoration: 'none',
  marginRight: '0.4rem',
  fontWeight: 700,
})

const PublishInfo = styled.div({
  ...typography.caption,
  marginBottom: '0.75rem',
  color: 'rgba(0,0,0,0.6)',
})

const Excerpt = styled.div({
  ...typography.caption,
  color: 'rgba(0,0,0,0.6)',
  maxHeight: 'calc(1.4rem * 3)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 4,
  WebkitBoxOrient: 'vertical',
})

interface Props {
  className?: string
  post: GhostPost
  featured?: boolean
}

const PostPreview = ({ post, className, featured }: Props) => {
  return (
    <div className={className}>
      {post.primary_tag !== null && (
        <div>
          <CategoryLink to={post.primary_tag.fields.permalink}>
            {post.primary_tag.name}
          </CategoryLink>
        </div>
      )}
      <Link
        to={post.fields.permalink}
        css={{ color: 'inherit', textDecoration: 'none' }}
      >
        <Title featured={featured}>{post.title}</Title>
      </Link>
      <PublishInfo>
        <PostAuthors post={ post } small></PostAuthors>
      </PublishInfo>
      <Link
        to={post.fields.permalink}
        css={{ color: 'inherit', textDecoration: 'none' }}
      >
        <Excerpt dangerouslySetInnerHTML={{ __html: post.custom_excerpt }} />
      </Link>
    </div>
  )
}

export default PostPreview
