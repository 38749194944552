import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { GhostPost } from 'types/ghost'
import PostPreview from 'components/PostPreview'

interface Props {
  post: GhostPost
}

const PostPreviewWithImage = ({ post }: Props) => (
  <div css={{ display: 'flex', flexDirection: 'column' }}>
    <Link to={post.fields.permalink}>
      <Img
        fluid={{
          ...(post.feature_image &&
            post.feature_image.localFile.childImageSharp.thumbnail),
          aspectRatio: 5 / 3,
        }}
        css={{ marginBottom: '0.75rem', borderRadius: 2 }}
        placeholderStyle={{
          filter: 'blur(16px)',
          transform: 'scale(1.04)',
        }}
      />
    </Link>
    <PostPreview post={post} />
  </div>
)

export default PostPreviewWithImage
