import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { GhostPost, GhostTag } from 'types/ghost'
import { typography, colors } from 'styles'

const Heading = styled.h2({
  ...typography.headline4,
  color: 'rgba(0,0,0,0.84)',
  display: 'inline-block',
  marginRight: '0.5rem',
})

const CategoryLink = styled(Link)({
  ...typography.body,
  color: colors.primary,
  textDecoration: 'none',
})

interface Props {
  category: GhostTag
  className?: string
  filterPosts?: (posts: GhostPost[]) => GhostPost[]
  renderPosts(posts: GhostPost[]): JSX.Element
}

const CategoryPosts = ({
  category,
  filterPosts = posts => posts,
  renderPosts,
  className,
}: Props) => {
  const categoryPosts = filterPosts(category.posts)

  return (
    <div className={className}>
      <div css={{ display: 'flex', marginBottom: '1rem' }}>
        <CategoryLink to={category.fields.permalink}>
          <Heading>{category.name}</Heading>
          see all
        </CategoryLink>
      </div>
      {renderPosts(categoryPosts)}
    </div>
  )
}

export default CategoryPosts
