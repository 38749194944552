const ServiceTags = {
  prefix: {
    TargetSite: '#target-site:',
  },

  TargetSiteDevo: 'devo',
}

module.exports.isSpecialTag = tagName => {
  const prefixes = Object.values(ServiceTags.prefix)
  return prefixes.some(prefix => tagName.startsWith(prefix))
}

module.exports.allowedTag = tag => {
  if (!tag || !tag.name) return true

  const [prefix, site] = tag.name.split(':')

  const prefixRx = /#target-site/
  const siteRx = new RegExp(`^\\s*${process.env.TARGET_SITE}\\s*$`)

  return !prefixRx.test(prefix) || siteRx.test(site)
}

module.exports.includePost = tags => {
  if (tags) {
    const targetedTags = tags.filter(tag =>
      tag.name.startsWith(ServiceTags.prefix.TargetSite)
    )
    const targetSites = targetedTags.map(tag =>
      tag.name
        .substr(ServiceTags.prefix.TargetSite.length)
        .replace(/^\s+|\s+$/g, '')
    )
    const currentSite = process.env.TARGET_SITE || 'production'

    const hasNoTargetSites = !targetSites.length
    const hasCorrectTargetSite = targetSites.some(site => site === currentSite)

    if (currentSite === 'production') {
      return hasNoTargetSites
    } else {
      return hasNoTargetSites || hasCorrectTargetSite
    }
  }

  return true
}
