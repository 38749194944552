import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { GhostPost } from 'types/ghost'

import PostPreview from 'components/PostPreview'
import { colors } from 'styles'

interface Props {
  className?: string
  post: GhostPost
}

const FeaturedPost = ({ post, className }: Props) => {
  return (
    <div
      className={className}
      css={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        borderRadius: 4,
        overflow: 'hidden',
        backgroundColor: colors.white,
        '@media (min-width: 595px)': {
          flexDirection: 'row',
        },
      }}
    >
      <Link css={{ flex: '0 0 50%' }} to={post.fields.permalink}>
        <Img
          fluid={{
            ...post.feature_image.localFile.childImageSharp.thumbnail,
            aspectRatio: 3 / 2,
          }}
          css={{ height: '100%' }}
          critical
          placeholderStyle={{
            filter: 'blur(16px)',
            transform: 'scale(1.04)',
          }}
        />
      </Link>
      <div css={{ padding: '2em' }}>
        <PostPreview post={post} featured />
      </div>
    </div>
  )
}

export default FeaturedPost
