import React from 'react'
import styled from '@emotion/styled'

import PostPreviewBasic from 'components/PostPreview'

// allow PostPreview component to be used as Emotion selector
const PostPreview = styled(PostPreviewBasic)()

const PostPreviewGrid = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: '-1.5rem',
  marginRight: '-1.5rem',

  [`& > ${PostPreview}`]: {
    margin: '1.5rem',
    width: 'calc(33% - 3rem)',
  },

  ['@supports (display: grid)']: {
    // reset
    marginLeft: 0,
    marginRight: 0,
    [`& > ${PostPreview}`]: {
      margin: '0',
      width: 'auto',
    },

    // grid
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
    gridGap: '3rem',
  },
})

export default PostPreviewGrid
