import React from 'react'
import { graphql } from 'gatsby'

import { GqlQueryResult } from 'types/graphql'
import { GhostPost, GhostTag } from 'types/ghost'
import FeaturedPost from 'components/FeaturedPost'
import PostPreviewGrid from 'components/PostPreviewGrid'
import CategoryPosts from 'components/CategoryPosts'
import PostPreviewWithImage from 'components/PostPreviewWithImage'
import PostPreview from 'components/PostPreview'
import Layout from 'components/Layout'
import { centeredContent } from 'styles'
import Header from 'components/Header'
import getPrimaryTags from 'lib/getPrimaryTags'
import postHasPrimaryTag from 'lib/postHasPrimaryTag'
import * as utils from 'lib/utils';

interface Props {
  data: {
    allGhostTag: GqlQueryResult<GhostTag>
    featuredPosts: GqlQueryResult<GhostPost>
  }
}

const createPostFilter = (category: GhostTag) => (posts: GhostPost[]): GhostPost[] =>
  posts
    .filter(post => postHasPrimaryTag(post, category))
    .sort((a, b) => Date.parse(b.published_at) - Date.parse(a.published_at))
    .slice(0, 3)

const IndexPage = ({ data }: Props) => {
  const [firstFeaturedPost, ...otherFeaturedPosts] = data.featuredPosts.edges.filter(({ node }) => {
    return utils.includePost(node.tags);
  });
  const featuredCategories = getPrimaryTags(data.allGhostTag)

  return (
    <Layout>
      <Header styles={{ padding: '1.6rem 1.6rem 6.4rem' }}></Header>
      <div
        css={[
          centeredContent(1080),
          { marginTop: '-4.8rem', marginBottom: '4.8rem' },
        ]}
      >
        <FeaturedPost post={firstFeaturedPost.node} />

        {/* other posts */}
        <PostPreviewGrid css={{ marginTop: '3rem' }}>
          {otherFeaturedPosts.slice(0, 3).map(({ node: post }) => (
            <PostPreview key={post.id} post={post} />
          ))}
        </PostPreviewGrid>

        {/* featured category */}
        {featuredCategories.map(({ node: category }) => (
          <CategoryPosts
            key={category.id}
            css={{ marginTop: '5rem' }}
            category={category}
            filterPosts={createPostFilter(category)}
            renderPosts={posts => (
              <PostPreviewGrid>
                {posts.map(post => (
                  <PostPreviewWithImage key={post.id} post={post} />
                ))}
              </PostPreviewGrid>
            )}
          />
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  fragment siteMeta on Query {
    site {
      siteMetadata {
        baseUrl
      }
    }
  }

  fragment ghostPostDetails on GhostPost {
    id: ghostId
    title
    html
    feature_image {
      src
      localFile {
        childImageSharp {
          thumbnail: fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    published_at
    updated_at
    custom_excerpt
    fields {
      permalink
    }
    authors {
      id
      name,
      profile_image {
        src
      }
      fields {
        permalink
      }
    }
    primary_author {
      id
      name
      bio
      profile_image {
        src
      }
      fields {
        permalink
      }
    }
    primary_tag {
      id
      name
      fields {
        permalink
      }
    }
    tags {
      id
      name
      fields {
        permalink
      }
      visibility
    }
  }

  query HomePostsQuery {
    featuredPosts: allGhostPost(
      filter: {
        featured: { eq: true }
        feature_image: { src: { ne: null } }
        custom_excerpt: { ne: null }
        # ... ideally this would check for a category too but ghost source plugin is weak.
      }
      sort: { fields: [published_at], order: DESC }
    ) {
      edges {
        node {
          ...ghostPostDetails
        }
      }
    }
    allGhostTag(filter: { visibility: { eq: "public" } }) {
      edges {
        node {
          id
          name
          fields {
            permalink
          }
          posts {
            ...ghostPostDetails
          }
        }
      }
    }
  }
`

export default IndexPage
